import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

// import { Section } from '../components/common/section';
import Hero from '../components/hero/index';
import Features from '../components/features/index';
import Slider from '../components/sliders/index';
import Pricing from '../components/pricing/index';
import Survey from '../components/survey/index';

const IndexPage = (props) => {
  return (
    <Layout
      currentPage='homepage'
      headerDark={true}
      // setCurrentBreakpoint={setCurrentBreakpointChildren}
    >

      <SEO 
        title="Home" 
        keywords={[`Pepio`, `application`, `sondage`, `survey`]}
        description=" Grâce à Pepio, sonder l’opinion n’a jamais été aussi simple. Donnez votre avis, développez votre communauté, visualisez leurs réponses, tout simplement."
        meta={[
          {
            property: `og:url`,
            content: 'https://pepio.io',
          },
          {
            property: `fb:app_id`,
            content: '591813588041433',
          }
        ]}
      />

      <Hero />
      <Features components="main" />
      <Features components="why" />
      <Slider components="tabs" />
      <Survey />
      <Pricing />
    </Layout>
  )
};

export default IndexPage
