import React from 'react';


class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }
  onClick(evt) {
    evt.preventDefault();
    const attr = Number(evt.target.getAttribute('data-action'));
    this.props.handleClick(attr);
  }
  render() {
    return (
      <div className="pricing__navigation">

        <button
          className="slider-btn"
          data-action="1"
          id='slider-next'
          onClick={this.onClick}
          onKeyPress={this.onClick}
        >
          next
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#005CCA" />
            <path d="M34.7071 24.7071C35.0976 24.3166 35.0976 23.6834 34.7071 23.2929L28.3431 16.9289C27.9526 16.5384 27.3195 16.5384 26.9289 16.9289C26.5384 17.3195 26.5384 17.9526 26.9289 18.3431L32.5858 24L26.9289 29.6569C26.5384 30.0474 26.5384 30.6805 26.9289 31.0711C27.3195 31.4616 27.9526 31.4616 28.3431 31.0711L34.7071 24.7071ZM14 25H34V23H14V25Z" fill="white" />
          </svg>
        </button>
        <button
          className="slider-btn"
          data-action="-1"
          id='slider-prev'
          onClick={this.onClick}
          onKeyPress={this.onClick}
        >
          prev
          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.292893 7.29289C-0.097631 7.68342 -0.0976311 8.31658 0.292893 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z" fill="white" />
          </svg>
        </button>
      </div>
    )
  }
}
export default Navigation