import React from 'react';

const Survey = (props) => {
  return (
    <section className="survey" id="survey-2" nav="true">
      <div>
        <h2 className="title">Je désire plus d’informations sur Pepio</h2>
        <a href="mailto:hello@pepio.io" className="btn btn--secondary btn-contact">Contactez-nous</a>
      </div>
    </section>
  );
}

export default Survey;