import React from 'react';

import Entries from './entries.js'
import Labels from './labels.js'
import Navigation from './navigation.js'

class Pricing extends React.Component {

  constructor(props) {
    super(props)
    this.container = React.createRef();
    this.labels = [
      'Nb. de catégorie de sondage (émission)',
      'Nb de sondage par catégorie (émission)',
      'Nbre de votants par sondage',
      'Taille de la cohorte maximum / traffic',
      'Nbre de questions par sondage',
      'Statistique sondage',
      'Personnalisation interface',
      'Publicités',
      'Export datas / dataViz, Cohorte',
      'Accès au chat',
      'Statistique cohorte',
      'Redressement statistique',
      'Plusieurs langues',
      'Intégration web',
      'Personnalisation Dataviz',
      'Intégration app',
      'Interface recherche et analyse',
    ]

    this.state = {
      current: 0,
    }

    // console.log('this.container', this.container);

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    // console.log('this.container componentDidMount', this.container, this.container.current.getBoundingClientRect().y, window.scrollY);
    this.min = this.container.current.getBoundingClientRect().y + window.scrollY
    this.step = window.innerHeight
    this.max = this.step
    this.height = this.min + this.container.current.getBoundingClientRect().height
    this.canUpdate = false
  }
  componentDidUpdate(){
    if(this.container.current) {
      // console.log('this.container.current', this.container.current, '-', this.container.current.getBoundingClientRect().y, window.scrollY);
      if (window.scrollY > this.min && window.scrollY <= this.height && window.innerWidth >= 1280) {
        this.container.current.classList.add('isSticky')
      } else {
        if(this.container.current.classList.contains('isSticky')){
          this.container.current.classList.remove('isSticky')
        }
      }
    }
    
  }
  handleClick(attr) {
    let current;

    if (this.state.current + attr >= 0 && attr < 0) current = this.state.current + attr
    else if (this.state.current + attr < 4 && attr > 0) current = this.state.current + attr
    else current = this.state.current

    this.setState({
      current: current
    })

  }

  render() {
    return (
      <section id="pricing" nav="true" limit="1">
        <div className="pricing">
          <header className="pricing__header">
            <strong className="suptitle">Pricing</strong>
            <h2 className="title">Pepio Maker, ça coûte combien ?</h2>

            {/* <div className="pricing__tabs">
              <button type="monthly" className="tab tab--active">Mensuel</button>
              <button type="yearly" className="tab">Annuel</button>
            </div> */}
          </header>
          <div ref={this.container}  className="pricing__container">
            <Labels labels={this.labels} />
            <Entries state={this.state} labels={this.labels} />
          </div>
          <div className="pricing__background">
            <svg viewBox="0 0 1440 1205" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1309.55 689.404L1444 539.971L1444 0.000244141L-2.00002 0.000117727L-2.00012 1205L153.446 846.255L532.815 846.255L1003.15 846.256L1151.1 689.404L1309.55 689.404Z" fill="#ECEDF2" />
            </svg>
          </div>
          <Navigation handleClick={this.handleClick} />
        </div>
      </section>
    )
  }
}

export default Pricing