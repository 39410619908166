import React from 'react';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all'

import Status0 from './../../images/status-icon-1.svg'
import Status1 from './../../images/status-icon-2.svg'
import Status2 from './../../images/status-icon-3.svg'
import Status3 from './../../images/status-icon-4.svg'

import Check from './../../images/check.svg'
import Unlimited from './../../images/unlimited.svg'

class Entry extends React.Component {

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.features = React.createRef();
    this.state = {
      open: false,
      label_more: 'Voir plus',
    }

    this.resizeTimer = null

    this.onClick = this.onClick.bind(this)
    this.onResize = this.onResize.bind(this)
  }
  componentDidMount() {
    this.left = this.container.current.offsetLeft;
    this.width = this.features.current.clientWidth
    this.height = this.features.current.clientHeight
    this.openHeight = this.features.current.scrollHeight
    this.top = this.container.current.getBoundingClientRect().top

    if (window.innerWidth < 768) this.slideOnMobile()
    if (window.innerWidth > 768 && window.innerWidth < 1280) this.slideOnIpad()
    window.addEventListener('resize', this.onResize)
  }
  componentDidUpdate(prevProps) {
    const isIpad = prevProps.className !== this.props.className && window.innerWidth > 768 && window.innerWidth < 1280
    const isMobile = prevProps.className !== this.props.className && window.innerWidth < 768

    if (isIpad && !this.state.open) this.slideOnIpad()
    else if (isIpad && this.state.open) gsap.to(this.features.current, .4, { height: this.height, onComplete: this.slideOnIpad() })

    if (isMobile && !this.state.open) this.slideOnMobile()
    else if (isMobile && this.state.open) gsap.to(this.features.current, .4, { height: this.height, onComplete: this.slideOnMobile() })

   
  }

  onResize() {
    if (this.resizeTimer) clearTimeout(this.resizeTimer)

    this.resizeTimer = setTimeout(() => this.delayedResize(), 150);
  }
  
  delayedResize() {
    this.left = this.container.current.offsetLeft;
    this.width = this.features.current.clientWidth
    this.height = this.features.current.clientHeight
    this.top = window.pageYOffset + this.container.current.getBoundingClientRect().top
    this.openHeight = this.features.current.scrollHeight
    if (window.innerWidth > 768 && window.innerWidth < 1280) this.slideOnIpad()
    else if (window.innerWidth <= 768) this.slideOnMobile()
    
    
    if (window.innerWidth >= 1280) {
      gsap.set(this.container.current, {clearProps: 'all'});
    }
  }


  slideOnIpad() {
    const margin = this.props.index !== 0 ? 20 : 0;
    if (this.props.className === "prev") gsap.to(this.container.current, .4, { x: -this.left + margin, scale: .9, opacity: 1, force3D: true, onComplete: () => { this.container.current.style.boxShadow = '0px 7px 30px rgba($darkblue, 0)' } });
    else if (this.props.className === "current") gsap.to(this.container.current, .4, { scale: 1, opacity: 1, x: -this.left + 40, force3D: true });
    else if (this.props.className === "next") gsap.to(this.container.current, .4, { scale: 1, opacity: 1, x: -this.left + this.width + 140, force3D: true });
    else gsap.to(this.container.current, .4, { scale: 1, opacity: 1, x: 200, force3D: true });
  }
  slideOnMobile() {
    const margin = this.props.index !== 0 ? 40 : 20;
    if (this.props.className === "prev") gsap.to(this.container.current, .4, { x: -this.left + 20, scale: .9, opacity: 1, force3D: true, onComplete: () => { this.container.current.style.boxShadow = '0px 7px 30px rgba($darkblue, 0)' } });
    else if (this.props.className === "current") gsap.to(this.container.current, .4, { scale: 1, opacity: 1, x: -this.left + margin, force3D: true });
    else if (this.props.className === "next") gsap.to(this.container.current, .4, { scale: 1, opacity: .6, x: -this.left + 20 + window.innerWidth, force3D: true });
    else gsap.to(this.container.current, .4, { scale: 1, opacity: 1, x: 200, force3D: true });

  }

  onClick() {
    this.setState({
      open: !this.state.open,
      label_more: !this.state.open === false ? 'Voir plus' : 'Voir moins',
    })
    gsap.to(this.features.current, .4, { height: !this.state.open ? this.openHeight : this.height })
    gsap.to(window, { duration: .4, scrollTo: { y: this.top, offsetY: 100 } });
  }
  render() {

    const features = this.props.entry.features.map((feature, i) => {
      let type, icon;
      if (typeof feature === 'boolean' && feature) {
        type = 'have'
        icon = Check
      }
      else if (typeof feature === 'boolean' && !feature) type = 'haveNot'
      else if (feature === 'unlimited') {
        type = 'unlimited'
        icon = Unlimited
      }
      else type = 'text'
      return (
        <li className={'features features--' + type} key={i}>
          <span className="label">{this.props.labels[i]}</span>
          <span style={{ backgroundImage: 'url(' + icon + ')' }}>{feature}</span>
        </li>
      )
    })

    this.images = [Status0, Status1, Status2, Status3];
    return (
      <li ref={this.container} className={this.props.className + " pricing__el"}>
        <div className="pricing__el-header">
          <img src={this.images[this.props.index]} alt={this.props.entry.status} />
          <span className="status">{this.props.entry.status}</span>
          <h3><span className="price">{this.props.entry.price}</span>
            {this.props.entry.month ? <span className="euro">€</span> : ''}
            {this.props.entry.month ? <span className="month">/ Mois</span> : ''}</h3>
          <hr />
          <a className="btn btn--primary btn-contact" href={this.props.entry.link}>Contactez-nous</a>
        </div>
        <div>
          <ul ref={this.features} className="pricing__features">
            {features}
          </ul>
        </div>
        <button onClick={this.onClick} className="more">{this.state.label_more}</button>

      </li >
    )
  }
}

export default Entry