import React from 'react';

import Entry from './entry.js'
const Entries = (props) => {

  const entries = [
    {
      status: "Pepio Maker Free",
      price: 'Gratuit',
      features: [
        '1',          //'Nb. de catégorie de sondage (émission)', 
        '5',          //'Nb de sondage par catégorie (émission)', 
        '200',        //'Nbre de votants par sondage', 
        '2.000',      //'Taille de la cohorte maximum / traffic', 
        '10',         //'Nbre de questions par sondage', 
        true,         //'Statistique sondage', 
        false,        //'Personnalisation interface', 
        true,         //'Publicités', 
        false,        //'Export datas / dataViz, Cohorte', 
        false,        //'Accès au chat', 
        false,        //'Statistique cohorte', 
        false,        //'Redressement statistique', 
        false,        //'Plusieurs langues', 
        false,        //'Intégration web', 
        false,        //'Personnalisation Dataviz', 
        false,        //'Intégration app', 
        false,        //'Interface recherche et analyse', 
      ],
      link: 'mailto:hello@pepio.io',
    },
    {
      status: "Pepio Maker Starter",
      price: '25',
      month: true,
      features: [
        '3',          //'Nb. de catégorie de sondage (émission)', 
        '10',         //'Nb de sondage par catégorie (émission)', 
        '1.000',      //'Nbre de votants par sondage', 
        '10.000',     //'Taille de la cohorte maximum / traffic', 
        '20',         //'Nbre de questions par sondage', 
        true,         //'Statistique sondage', 
        false,        //'Personnalisation interface', 
        false,        //'Publicités', 
        true,         //'Export datas / dataViz, Cohorte', 
        true,         //'Accès au chat', 
        false,        //'Statistique cohorte', 
        false,        //'Redressement statistique', 
        false,        //'Plusieurs langues', 
        false,        //'Intégration web', 
        false,        //'Personnalisation Dataviz', 
        false,        //'Intégration app', 
        false,        //'Interface recherche et analyse', 
      ],
      link: 'mailto:hello@pepio.io',
    },
    {
      status: "Pepio Maker Pro",
      price: '199',
      month: true,
      features: [
        '5',            //'Nb. de catégorie de sondage (émission)', 
        'unlimited',     //'Nb de sondage par catégorie (émission)', 
        '5.000',        //'Nbre de votants par sondage', 
        '50.000',       //'Taille de la cohorte maximum / traffic', 
        'unlimited',     //'Nbre de questions par sondage', 
        true,           //'Statistique sondage', 
        false,          //'Personnalisation interface', 
        false,          //'Publicités', 
        true,           //'Export datas / dataViz, Cohorte', 
        true,           //'Accès au chat', 
        true,           //'Statistique cohorte', 
        true,           //'Redressement statistique', 
        true,           //'Plusieurs langues', 
        true,           //'Intégration web', 
        false,          //'Personnalisation Dataviz', 
        false,          //'Intégration app', 
        false,          //'Interface recherche et analyse', 
      ],
      link: 'mailto:hello@pepio.io',
    },
    {
      status: "Pepio Maker Master",
      price: 'Devis',
      features: [
        'unlimited',          //'Nb. de catégorie de sondage (émission)', 
        'unlimited',          //'Nb de sondage par catégorie (émission)', 
        'devis',        //'Nbre de votants par sondage', 
        'unlimited',      //'Taille de la cohorte maximum / traffic', 
        'unlimited',         //'Nbre de questions par sondage', 
        true,         //'Statistique sondage', 
        true,        //'Personnalisation interface', 
        false,         //'Publicités', 
        'devis',        //'Export datas / dataViz, Cohorte', 
        true,        //'Accès au chat', 
        true,        //'Statistique cohorte', 
        true,        //'Redressement statistique', 
        true,        //'Plusieurs langues', 
        true,        //'Intégration web', 
        true,        //'Personnalisation Dataviz', 
        true,        //'Intégration app', 
        true,        //'Interface recherche et analyse', 
      ],
      link: 'mailto:hello@pepio.io',
    },
  ];

  const list = entries.map((entry, i) => {
    let status = '';
    if (props.state.current === i) status = 'current';
    else if (props.state.current > i) status = 'prev';
    else if (props.state.current + 1 === i) status = 'next';
    return <Entry key={i} index={i} state={props.state.current} className={status} entry={entry} labels={props.labels} />
  });

  return (
    <ul className="pricing__list">
      {list}
    </ul>
  )
}


export default Entries