import React from 'react';
// import gsap from 'gsap';

import { Tab } from './tab'

import Bg from '../../../images/background-tab.svg'
import SVG_1 from './tab-1'
import SVG_2 from './tab-2'
import SVG_3 from './tab-3'
import SVG_4 from './tab-4'

class Tabs extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      current: 1
    }

    this.container = React.createRef();
    this.entries = [
      {
        title: 'Des enquêtes qui se créent facilement',
        content: 'Pepio se veut fonctionnel et facile à utiliser pour tout le monde. La mise en place de notre aide à la création de sondage permet une prise en main facile et efficace.',
        svg: <SVG_1 />
      },
      {
        title: 'Visualisez les résultats en temps réel.',
        content: 'Spécialiste de la data visualisation, Pepio vous présente les résultats à travers des graphiques adaptés en live.',
        svg: <SVG_3 />
      },
      {
        title: 'Déterminez la représentativité ',
        content: 'Profilez votre panel grâce à notre méthodologie et améliorez la connaissance de votre public selon vos besoins.',
        svg: <SVG_4 />

      },
      {
        title: 'Exportez des rapports de qualités',
        content: 'Partagez des résultats à votre image pour un meilleur impact auprès de vos partenaires.',
        svg: <SVG_2 />
      },
    ]

    this.handleClick = this.handleClick.bind(this);
  }


  componentDidMount() {
    this.min = this.container.current.offsetTop + 120 + window.scrollY
    this.step = window.innerHeight
    this.max = this.step
    this.height = this.min + this.container.current.getBoundingClientRect().height
    this.canUpdate = false
  }


  componentDidUpdate() {
    if (window.innerWidth <= 1023) return
    const state = this.props.props.state
    let number;
    if (window.scrollY > this.min && window.scrollY <= this.height) {
      if (window.scrollY > this.min + this.max && !this.canUpdate && state.down && this.state.current !== 4) {
        this.max = this.max + this.step
        number = 1
        this.canUpdate = true
      }
      if (window.scrollY < this.min + this.max && !this.canUpdate && !state.down && this.state.current !== 1) {
        this.max = this.max - this.step
        number = -1
        this.canUpdate = true
      }
      if (this.canUpdate) {
        this.canUpdate = false
        this.setState({ current: this.state.current + number })
      }

    }
  }


  handleClick(index, scroll) {
    console.log('handleClick', index, scroll)
    if (window.innerWidth <= 1023) return
    const max = this.step * index
    if (index === 4) scroll = this.min + max - 200
    else scroll = this.min + max
    window.scrollTo(0, scroll)
  }

  render() {
    this.slides = [...this.entries].map((entry, i) => {
      return <Tab key={i} entry={entry} state={this.state} handleClick={this.handleClick} index={i + 1} />
    })


    return (
      <section
        ref={this.container}
        id="survey-1"
        nav="true"
        limit="1"
        className="slider | tabs">

        <div className="sticky">
          <header className="tabs__header">
            <strong className="suptitle">Créer des enquêtes</strong>
            <h2>Pepio Maker tout simplement</h2>
          </header>

          <div className="tabs__slider">
            <ol>
              {this.slides}
            </ol>
            <img className="tabs__bg" src={Bg} alt="bakground" />
          </div>
        </div>
      </section>
    );
  }
}


export default Tabs