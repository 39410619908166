import React from 'react';

class Tab extends React.Component {
  constructor(props) {
    super(props)

  }
  render() {
    const isCurrent = this.props.index === this.props.state.current ? 'current' : '';
    return (
      <li className={"tab " + isCurrent}>
        <h3 onClick={() => this.props.handleClick(this.props.index)} className="h4 tab__navigation"><span className="number">{this.props.index}.</span> {this.props.entry.title}</h3>
        <div className="tab__content">
          <div className="tab__svg">
            {this.props.entry.svg}
          </div>
          <p className="tab__text">{this.props.entry.content}</p>
        </div>
      </li>
    );
  }

}


export {
  Tab
}