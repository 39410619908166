import React from 'react';
import { gsap, Power3 } from 'gsap';


import circle1 from './../../images/circle-1.jpg'
import circle2 from './../../images/circle-2.jpg'
import circle3 from './../../images/circle-3.jpg'
import circle4 from './../../images/circle-4.jpg'
import circle5 from './../../images/circle-6.jpg'
import circle6 from './../../images/circle-5.jpg'
class Circles extends React.Component {
  constructor(props) {
    super(props);

    this.entries = [
      {
        src: circle1,
        ratio: 136,
        x: -45,
        y: 226
      },
      {
        src: circle2,
        ratio: 85,
        x: 480,
        y: 2
      },
      {
        src: circle3,
        ratio: 85,
        x: 560,
        y: 493
      },
      {
        src: circle4,
        ratio: 85,
        x: 1095,
        y: 648
      },
      {
        src: circle5,
        ratio: 52,
        x: 588,
        y: 796
      },
      {
        src: circle6,
        ratio: 52,
        x: 588,
        y: 796
      },
    ];

    this.images = []

    this.onResize = this.onResize.bind(this)
  }
  componentDidMount() {


    this.els = document.querySelectorAll('.circle--picture')
    this.endPoint = document.querySelector('.feature.main')

    this.xT = window.innerWidth / 2
    this.yT = this.endPoint.getBoundingClientRect().y + 100
    this.els.forEach(el => {
      el.xS = el.getBoundingClientRect().x + el.getBoundingClientRect().width / 2
      el.yS = el.getBoundingClientRect().y + el.getBoundingClientRect().height / 2 - 300
      el.distX = this.xT - el.xS
      el.distY = this.yT - el.yS
    })
    if(!'ontouchstart' in window){
      window.addEventListener('resize', this.onResize)
    }
  }

  onResize() {
    this.xT = window.innerWidth / 2
    this.yT = this.endPoint.getBoundingClientRect().y + 100

    this.els.forEach(el => {
      el.distX = this.xT - el.xS
      el.distY = this.yT - el.yS
    })
  }

  componentDidUpdate() {
    const state = this.props.props.scroll
    const progress = gsap.utils.clamp(0, 1, state / this.yT)
    if (state < this.yT) {
      this.els.forEach(el => {
        gsap.to(el, .5, { x: el.distX * progress, y: el.distY * progress, scale: 1 - progress / 3, ease: Power3.easeOut, force3D: true })
      })
    }

  }
  render() {

    this.images = this.entries.map((image, i) => {
      return (
        <span key={i} className="circle circle--picture">
          <img alt="" src={image.src} />
        </span>
      )
    })
    return (
      <div className="hero__circles">{this.images}</div>
    );
  }
}
export default Circles