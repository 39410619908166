import React from 'react';

const Video = (props) => {
  return (
    <li className={props.className + " slide"} step={props.index}>
      <div className="slide__video">
        {/* <video poster={props.data.placeholder}>
          <source src="/media/examples/flower.webm"
                  type="video/webm" />

          <source src="/media/examples/flower.mp4"
                  type="video/mp4" />

          Sorry, your browser doesn't support embedded videos.
        </video> */}
        <span className="video__player"></span>
      </div>
      <div className="slide__content">
        <strong className="slide__suptitle">{props.data.suptitle}</strong>
        <h3 className="title">{props.data.title}</h3>
        <p>{props.data.content}</p>
      </div>
      </li>
  );
}

export default Video