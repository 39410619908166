import React from 'react';

import imgEntreprise from './../../../images/entreprise.svg';
import imgMedia from './../../../images/media.svg';
import imgCadre from './../../../images/cadre.svg';

const Entry = (props) => {
  return (
    <li className="why__el">
      <span className="icon">
        <img src={props.entry.icon} alt={props.entry.title} />
      </span>
      <h3>{props.entry.title}</h3>
      <p>{props.entry.content}</p>
    </li>
  );
}

const List = (props) => {
  const entries = [
    {
      icon: imgEntreprise,
      title: 'Entreprises',
      content: 'Sondez vos clients, interagissez avec votre communauté, réalisez des études de marché ou de faisabilité, dynamisez vos AG ou CA, …'
    },
    {
      icon: imgMedia,
      title: 'Media',
      content: 'Sondez l’opinion en temps réel, interagissez avec vos auditeurs, spectateurs, lecteurs, dynamisez vos événements, …'
    },
    {
      icon: imgCadre,
      title: 'Particuliers',
      content: 'Posez des questions à vos proches en vue d’un départ en vacances, d’une fête ou cérémonie, sondez l’avis de la population sur une idée, un engagement social, …'
    }
  ];

  const list = entries.map((entry, i) => <Entry key={i} entry={entry} />);

  return (
    <ul className="why__list">{list}</ul>
  )
}

class Why extends React.Component {
  constructor(props) {
    super(props);
    this.appeared = false
    this.container = React.createRef()
    this.limit = -1000
  }
  componentDidMount() {
    this.top = this.container.current.getBoundingClientRect().top
  }
  componentDidUpdate() {
    const state = this.props.props.state

    if (!this.appeared && state.scroll > this.top - 300) {
      this.container.current.classList.add('isVisible')
      this.appeared = true
    }
  }
  render() {
    return (
      <section id="survey" nav="true" ref={this.container} className="feature | why">
        <header className="why__header">
          <strong className="suptitle">Créer des enquêtes</strong>
          <h2 className="title">Pourquoi utiliser Pepio ?</h2>
          <p>Vous voulez sonder, analyser les opinions de votre communaute? Pepio est là pour vous aider.</p>
        </header>
        <List />
      </section>
    );
  }
}

export default Why