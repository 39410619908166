import React from 'react';

import Video from './video.js'
import Navigation from './navigation.js'

import vP1 from './../../../images/video-1-placeholder.jpg'

class Videos extends React.Component{

  constructor(props){
    super(props)
    this.datas = [
      {
        suptitle: 'Episode 01',
        title: 'Un deuxième vidéo ?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: 'video',
        placeholder: 'https://images.unsplash.com/photo-1595341595379-cf1cb694ea1f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2542&q=80'
      },
      {
        suptitle: 'Episode 02',
        title: 'Un sondage qualifié, c’est quoi ?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        video: 'video',
        placeholder: vP1
      }
    ]
    this.state = {
      current: 0,
    }
    
    
    
    
    this.handleClick = this.handleClick.bind(this)
  }



  handleClick(attr){
    let current;
    if(this.state.current + attr < 0 && attr < 0) current = this.slides.length - 1
    else if(this.state.current + attr >= 0 && attr < 0) current = this.state.current + attr
    else if(this.state.current + attr > this.slides.length - 1 && attr > 0) current = 0
    else if(this.state.current + attr < this.slides.length && attr > 0) current = this.state.current + attr

    this.setState({
      current: current
    })
  }


  render(){ 

    this.slides = [...this.datas].map((data, i) => {
      return <Video index={i} state={this.state} className={this.state.current===i ? 'current' : ''} key={i} data={data} />
    })
    return (
      <section id="tuto" nav="true" className="slider | videos">

        <header className="videos__header">
          <strong className="suptitle">Tutoriaux</strong>
          <h2>De nombreux tuto pour vous aider à créer des enquêtes statistiques</h2>
        </header>

        <ul className="videos__slider">
          {this.slides}
        </ul>
      
        <Navigation handleClick={this.handleClick} />
          
     </section>
    );
  }
}




export default Videos