import React from 'react';
import Videos from './videos/index';
import Tabs from './tabs/index';

const Slider = (props) => {

    const components = {
      videos : Videos,
      tabs : Tabs,
    };
    const Components = components[props.components]
    return (<Components props={props}/>);
}

export default Slider