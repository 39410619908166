import React from 'react';

const Labels = (props) => {

  const list = props.labels.map((l, i) => <li className="label" key={i}>{l}</li>)
  return (
    <ul className="pricing__labels">
      {list}
    </ul>
  )
}

export default Labels;