import React from 'react';

import Main from './main/index';
import Why from './why/index';

const Features = (props) => {
  const components = {
    main : Main,
    why : Why,
  };
  const Components = components[props.components]
  return (<Components props={props} />);
}

export default Features